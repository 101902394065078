




























import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 0,
            pageSize: 10,
            total: 0,
            list: [],
        }
    },
    mounted() {
        this.onInit()
    },
    computed: {
        current(): any {
            return this.$store.state.current
        },
        managerInfo(): any {
            return this.$store.state.managerInfo
        },
        isExpert(): boolean {
            return this.current.role == 1
        }
    },
    methods: {
        onInit() {
            this.pageNum = 1
            this.getData()
        },
        onLoad() {
            this.pageNum += 1
            this.getData()
        },
        // 获取我的提问列表
        getData() {
            const { pageNum, pageSize } = this
            // 若是专家，应传专家ID参数
            let expertId = ''
            if (this.current?.role == 1 ) expertId = this.managerInfo?.id
            const params = { pageNum, pageSize, expertId }
            request.get('/api/chat/rest_live_chat/page', params).then(({list, total})=> {
                this.fillReciprocal(list)
                // console.log(list)
                if(pageNum==1) this.list = list
                else this.list = this.list.concat(list)
                this.total = total
            })
        },
        async fillReciprocal(list) {
            if(!list || list.length == 0) return
            // list.forEach((e: any) => {
            for (let e of list) {
                const id =  this.getReciprocalId(e)
                // e.name = ''
                // e.headimgurl = ''
                if(!id) return
                
                if(this.isExpert) {
                    request.get(`/api/account/rest_account/${id}`).then((acco: any) => {
                        // console.log(acco)
                        // e.headimgurl = acco.headimgurl
                        // e.name = acco.nickname
                        this.$set(e, 'headimgurl', acco.headimgurl)
                        this.$set(e, 'name', acco.nickname)
                    })
                } else {
                    // console.log(id)
                    let relationData = {}as any
                    await request.get(`/api/tobacco/expert/${id}`).then(data => {
                        // console.log(data)
                        relationData = data
                    })
                    // console.log(relationData)
                    // console.log('--------------')
                    const obj = await this.getExpertImgTwo(relationData, relationData.relationId)
                    // console.log(obj)
                    this.$set(e, 'headimgurl', obj.imgurlhead)
                    this.$set(e, 'imgUrl', obj.imgUrl)
                    this.$set(e, 'name', relationData.name)
                    // request.get(`/api/tobacco/manager/info/${id}`).then((acco: any) => {
                    //     e.headimgurl = '/static/images/avator.png'
                    //     e.name = acco.name
                    // })
                }
            // })
            }
        },
        getExpertImgTwo(expertChat, relationId) {
            let imgurlhead = ''
            let imgUrl = ''
            return request.get(`/api/account/rest_account/${relationId}`).then(res => {
                if (res) {
                    imgurlhead = res.headimgurl
                    imgUrl = ''
                } else {
                    imgurlhead = ''
                    if (expertChat.sex === 1) {
                        imgUrl = '/static/images/sex_1.png'
                    } else if (expertChat.sex === 2) {
                        imgUrl = '/static/images/sex_2.png'
                    } else {
                        imgUrl = '/static/images/sex_0.png'
                    }
                }
                return {imgurlhead, imgUrl}
            })
        },
        getReciprocalId(item: any) {
            // 若是专家，应比较专家ID参数
            let currentId = this.current.id
            if (this.isExpert) currentId = this.managerInfo.id
            if(item.fromId === currentId) return item.toId
            else return item.fromId
        },
        gotoim(item) {
            // console.log(item)
            const id =  this.getReciprocalId(item)
            // console.log(id)
            const headimgurl = item.headimgurl? item.headimgurl : item.imgUrl
            // console.log(headimgurl)
            this.$router.push({
                path: `/im/${id}`,
                query: {
                    headimgurl
                }
            })
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            if(isToday(time)) {
                return time.format('HH:mm')
            }
            return time.format('YYYY/M/D HH:mm')
        }
    }
})
function isToday(momentDate: moment.Moment) {
    return momentDate.isSame(moment().startOf('day'), 'd')
}
